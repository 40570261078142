import { useState } from 'react'
import { isEmailValid } from '~/lib/utils'
import { joinEmailList } from '~/lib/hubspot'
import { getCategories, getPosts, getBlogInformation } from '~/lib/blog'
import { IArticles } from './Isa'

export const getHome = async () => {
  const blogPosts = await getPosts({ pages: 3 })
  const blogCategories = await getCategories()
  const { homeQuestions, homeStats, news } = await getBlogInformation()

  const allHomeStats = homeStats?.map((info) => ({
    prefix: info.prefix,
    suffix: info.sulfix,
    text: info.text,
    value: info.value,
  }))

  const cleanCategories = blogCategories.map((category) => ({
    id: category.id,
    name: category.name,
    description: category.description,
  }))

  const homeNews = news.map((newSection: IArticles) => ({
    image: newSection.image,
    title: newSection.title,
    text: newSection.text,
    article_link: newSection.article_link,
  }))

  const posts = blogPosts.map((post) => {
    const getTag = () => {
      const tag = cleanCategories.filter((category) => category.id === post.categories[0])
      return tag[0].name || 'Provi'
    }

    return {
      id: post.id,
      title: post.title.rendered,
      image: post.jetpack_featured_media_url,
      alt: post.title.rendered,
      link: post.link,
      tag: getTag(),
    }
  })

  return { posts, homeQuestions, allHomeStats, homeNews }
}

export const useNewsletter = () => {
  const [email, setEmail] = useState('')
  const [isChecked, setIsChecked] = useState(false)
  const [hasJoinedEmailList, setHasJoinedEmailList] = useState(false)
  const [hasError, setHasError] = useState(false)

  const isButtonValid = isChecked && isEmailValid(email)

  const isInputValid = isEmailValid(email)

  const getIdPosition = (id: string) => {
    const position = document.querySelector<HTMLElement>(`#${id}`)?.offsetTop
    return position || 0
  }

  const scrollToPosition = (id: string) => {
    window.scrollTo({
      top: getIdPosition(id) - 72, // 72px for the Menu size
      left: 0,
      behavior: 'smooth',
    })
  }
  const handleRadio = () => {
    setIsChecked(!isChecked)
  }

  const handleInput = (inputEmail: string) => {
    setEmail(inputEmail)
  }

  const handleSubmit = async () => {
    setHasError(false)
    setHasJoinedEmailList(false)

    const response = await joinEmailList({ email })

    if (isButtonValid && response?.status !== 'error') {
      setHasJoinedEmailList(true)
    }

    if (response?.status === 'error') {
      setHasError(true)
    }
  }
  return {
    scrollToPosition,
    handleSubmit,
    handleInput,
    isChecked,
    handleRadio,
    isInputValid,
    isButtonValid,
    hasJoinedEmailList,
    hasError,
  }
}
