import { InputFieldV3 as Input, Radio, ButtonV3 as Button } from '@provi/provi-components'
import { useNewsletter } from '~/hooks/Home'
import {
  NewsletterWrapper,
  InputRadioWrapper,
  NewsletterContent,
  NewsletterTextContent,
  NewsletterTitle,
  NewsletterText,
  NewsletterMessage,
} from './styles'
import Link from 'next/link'

export const Newsletter = () => {
  const {
    handleSubmit,
    handleInput,
    isChecked,
    handleRadio,
    isInputValid,
    isButtonValid,
    hasJoinedEmailList,
    hasError,
  } = useNewsletter()

  return (
    <NewsletterWrapper>
      <NewsletterContent>
        <NewsletterTextContent>
          <NewsletterTitle>Acompanhe as novidades</NewsletterTitle>
          <NewsletterText>Assine a nossa newsletter e receba conteúdos pensados para o seu negócio.</NewsletterText>
        </NewsletterTextContent>

        <div>
          <Input
            label="Seu e-mail"
            placeholder="ana@email.com"
            width="496px"
            mobileWidth="312px"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput(e.target.value)}
          />

          <InputRadioWrapper className="input-radio">
            <Radio
              text={
                <>
                  Declaro que li a{' '}
                  <Link href="/termos-de-uso" legacyBehavior>
                    <a href="/termos-de-uso" target="_blank">
                      Política de privacidade
                    </a>
                  </Link>{' '}
                  da Provi
                </>
              }
              name="radio"
              checked={isChecked}
              onChange={handleRadio}
              disabled={!isInputValid}
            />
          </InputRadioWrapper>

          <Button text="Assine agora" width="184px" mobileWidth="184px" disabled={!isButtonValid} onClick={handleSubmit} />
          {hasError && <NewsletterMessage hasError={hasError}>Ocorreu um erro, tente novamente mais tarde</NewsletterMessage>}
          {hasJoinedEmailList && (
            <NewsletterMessage>A partir de agora você está acompanhando a melhor newsletter do Brasil!</NewsletterMessage>
          )}
        </div>
      </NewsletterContent>
    </NewsletterWrapper>
  )
}
