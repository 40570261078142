import { IArticles, IIsaSummit, ILogos, IStats } from '~/hooks/Isa'
import { ICollapseSection, IRawQuestion, IGetCategories, IProviQuestion, IProviderTestimonial } from 'types'
import axios from 'axios'
import { ROOT_URL } from '~/constants'

export const blog = axios.create({
  baseURL: ROOT_URL?.MS_BLOG || '',
})

interface IGetPosts {
  id: number
  title: {
    rendered: string
  }
  jetpack_featured_media_url: string // eslint-disable-line camelcase
  link: string
  categories: number[]
}

export const getPosts = async ({ pages }: { pages: number }): Promise<IGetPosts[]> => {
  try {
    const { data } = await blog.get(`/wp/v2/posts?per_page=${pages}`)
    return data
  } catch (error: any) {
    return error?.response?.data?.error?.message || error?.response?.data?.error?.name || 'Ocorreu um erro inesperado'
  }
}

export const getCategories = async (): Promise<IGetCategories[]> => {
  try {
    const { data } = await blog.get('wp/v2/categories')
    return data
  } catch (error: any) {
    return error?.response?.data?.error?.message || error?.response?.data?.error?.name || 'Ocorreu um erro inesperado'
  }
}

interface IGetBlogInformation {
  isaStats: IStats[]
  homeStats: IStats[]
  news: IArticles[]
  newsIsa: IArticles[]
  isa: IIsaSummit
  homeQuestions?: ICollapseSection[]
  comoFuncionaQuestions?: ICollapseSection[]
  isaQuestions?: IProviQuestion[]
  provipayAllQuestions?: IProviQuestion[]
  proviQuestions: IProviQuestion[]
  providerTestimonials: IProviderTestimonial[]
  partnersIsa: ILogos[]
}

export const getBlogInformation = async (): Promise<IGetBlogInformation> => {
  try {
    const { data } = await blog.get('/acf/v3/options/options')

    const homeQuestionsIds = data?.acf.featured_home_questions.map((item: { question: number }) => item.question)

    const comoFuncionaQuestionsIds = data?.acf.featured_como_funciona_questions.map(
      (item: { question: number }) => item.question
    )

    const query = homeQuestionsIds.reduce((queryString: string, id: number) => `${queryString}&include[]=${id}`, '?')

    const comoFuncionaQuery = comoFuncionaQuestionsIds.reduce(
      (queryString: string, id: number) => `${queryString}&include[]=${id}`,
      '?'
    )

    const { data: questions } = await blog.get(`/wp/v2/perguntas${query}`)

    const { data: comoFuncionaProviQuestions } = await blog.get(`/wp/v2/perguntas${comoFuncionaQuery}`)

    const homeQuestions = questions.reverse().map(
      (question: IRawQuestion): ICollapseSection => ({
        title: question.title.rendered,
        content: question.acf.simplified_answer || question.acf.full_answer,
        width: '100%',
      })
    )

    const isaQuestionsIds = data?.acf.featured_isa_questions.map((item: { question: number }) => item.question)

    const isaQuery = isaQuestionsIds.reduce((queryString: string, id: number) => `${queryString}&include[]=${id}`, '?')

    const { data: isaQuestions } = await blog.get(`/wp/v2/perguntas${isaQuery}`)

    const provipayQuestionsIds = data?.acf.featured_provipay_questions.map((item: { question: number }) => item.question)

    const provipayQuery = provipayQuestionsIds.reduce(
      (queryString: string, id: number) => `${queryString}&include[]=${id}`,
      '?'
    )

    const { data: provipayAllQuestions } = await blog.get(`/wp/v2/perguntas${provipayQuery}`)

    const comoFuncionaQuestions = comoFuncionaProviQuestions.map(
      (question: IRawQuestion): ICollapseSection => ({
        title: question.title.rendered,
        content: question.acf.simplified_answer || question.acf.full_answer,
        width: '100%',
      })
    )

    const proviQuestionsIds = data?.acf.featured_provi_questions.map((item: { question: number }) => item.question)

    const proviQuery = proviQuestionsIds.reduce((queryString: string, id: number) => `${queryString}&include[]=${id}`, '?')

    const { data: proviQuestions } = await blog.get(`/wp/v2/perguntas${proviQuery}`)

    const providerTestimonials = data?.acf.provider_testimonials.map((testimonial: any) => ({
      name: testimonial.full_name,
      label: testimonial.job_title,
      text: testimonial.testimonial,
      image: testimonial.image.sizes.large,
    }))

    return {
      isaStats: data?.acf.isa_stats,
      homeStats: data?.acf.home_stats,
      news: data?.acf.news,
      newsIsa: data?.acf.news_isa,
      isa: data?.acf.isa,
      partnersIsa: data?.acf.logos,
      homeQuestions,
      comoFuncionaQuestions,
      isaQuestions,
      provipayAllQuestions,
      proviQuestions,
      providerTestimonials,
    }
  } catch (error: any) {
    return error?.response?.data?.error?.message || error?.response?.data?.error?.name || 'Ocorreu um erro inesperado'
  }
}

export const getAllFAQQuestions = async (): Promise<IRawQuestion[]> => {
  try {
    const rawQuestions: IRawQuestion[] = (await blog.get('/wp/v2/perguntas?per_page=100')).data

    // remove from wordpress when is fixed
    const employabilityIds: number[] = [1216, 1217, 1218]
    const rawQuestionsWithoutEmployability: IRawQuestion[] = rawQuestions.filter(
      (question) => !employabilityIds.includes(question.id)
    )

    return rawQuestionsWithoutEmployability
  } catch {
    return []
  }
}

export const getFAQSections = async (): Promise<IGetCategories[]> => {
  try {
    const { data } = await blog.get('wp/v2/secoes')
    return data.map(
      (rawSection: IGetCategories): IGetCategories => ({
        id: rawSection.id,
        description: rawSection.description,
        name: rawSection.name,
      })
    )
  } catch (error: any) {
    return error?.response?.data?.error?.message || error?.response?.data?.error?.name || 'Ocorreu um erro inesperado'
  }
}

export const getCasesSucesso = async () => {
  try {
    return await blog.get('/acf/v3/options/options/cases_sucesso')
  } catch (error: any) {
    return error?.response?.data?.error?.message || error?.response?.data?.error?.name || 'Ocorreu um erro inesperado'
  }
}
