import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'

interface INewsletterMessage {
  hasError?: boolean
}

export const NewsletterWrapper = styled.div`
  margin: 40px auto;
  min-height: 356px;
  padding: 24px;
  background-color: #fff;
`

export const NewsletterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;

  ${theme.breakpoints.up('md')} {
    flex-direction: row;
    align-content: center;
    align-items: baseline;
    max-width: 1016px;

    > div:nth-child(2) {
      margin: 51px 24px;
    }
  }
`

export const NewsletterTextContent = styled.div`
  max-width: 392px;
  width: 100%;

  ${theme.breakpoints.down('md')} {
    max-width: unset;
    margin-bottom: 21px;
  }
`

export const NewsletterTitle = styled.h2`
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 31.25px;
  line-height: 40px;
  color: #000000;

  ${theme.breakpoints.down('md')} {
    margin-bottom: 18px;
    font-size: 22.81px;
    line-height: 36px;
    letter-spacing: 0.005em;
  }
`

export const NewsletterText = styled.span`
  width: 100%;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.005em;
  color: #000000;

  ${theme.breakpoints.down('md')} {
    font-size: 15.84px;
    line-height: 24px;
  }
`

export const InputRadioWrapper = styled.div`
  margin: 24px 0;

  span {
    z-index: 1;

    a {
      color: black;
      text-decoration: none;
      letter-spacing: 0.005em;
      font-weight: 600;
      z-index: 2;
    }

    :hover a {
      color: #00198a;
      text-decoration: underline;
    }

    :active a {
      color: #00198a;
    }
  }

  input:checked ~ span > a {
    color: #2647d7;
  }

  input:hover ~ span > a {
    color: #00198a;
  }
`

export const NewsletterMessage = styled.div<INewsletterMessage>`
  margin-top: 8px;
  max-width: 75%;

  ${({ hasError }) =>
    hasError &&
    css`
      color: ${theme.colors.pink900};
    `}
`
